<template>
    <div class="d-flex flex-row">
      <div
        class="flex-row-auto"
        id="kt_profile_aside"
      >
        <div class="card card-stretch">
          <div class="card-body pt-4">
            <div class="d-flex align-items-center">
              <div
                class="symbol symbol-80 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
              >
              <div class="symbol-label"
                style="font-size: medium; color: black; font-weight: bold;"
                >{{clientInfo.debnr  }}</div>
                </div>
              <div>
                <!-- <a
                  class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                  >{{clientInfo.debnaam}}</a> -->
                <div class="text-muted">{{clientInfo.debnaam}}</div>
                <div class="mt-2">
                  <a
                    v-on:click="GoToGoogleMaps()"
                    class="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3  my-1"
                    >MAPS</a
                  >
                  <a
                    v-bind:href="`${clientInfo.webshopUrl}/Account/LoginByUrl?user=${clientInfo.debnr}&pass=${clientInfo.password}`"
                    target="_blank"
                    class="btn btn-sm btn-success font-weight-bold py-2 px-3  my-1 "
                    >WEBSHOP</a
                  >
                </div>
              </div>
            </div>
            <!--end::User-->
            <!--begin::Contact-->
            <div class="py-9">
              <div class="d-flex align-items-center justify-content-between ">
                <span class="font-weight-bold mr-2">Password:</span>
                </div>
                <div class="mb-2" ><a class="text-muted" @mouseover="HidePassword(false)" @mouseleave="HidePassword(true)" >
                  {{this.showPassword}}
                </a>
                </div>
                <div class="d-flex align-items-center justify-content-between ">
                <span class="font-weight-bold mr-2">Email:</span>
                </div>
                <div class="mb-2"><a class="text-muted">{{clientInfo.email}}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between ">
                <span class="font-weight-bold mr-2">Address:</span>
                </div>
                <div class="mb-2"><a class="text-muted">
                  {{ clientInfo.debAddress }} <br> {{ clientInfo.city }} {{ clientInfo.zipcode }} <br> {{clientInfo.country}}
                </a>
                </div>
                <dic></dic>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Phone:</span>
              </div>
              <div class="mb-2">
                <span class="text-muted">{{clientInfo.phone}}</span>
              </div>
            </div>
            <!--end::Contact--> 
          </div>
        </div>
      </div>
      <!--begin::Content-->
      <div class="flex-row-fluid ml-lg-8">
      <div class="ml-5 card gutter-b">
        <div class="p-10" v-if="loading">
            <div class="spinner spinner-primary spinner-md spinner-center"></div>
            <p class="text-6xl font-bold text-center text-gray-500 animate-pulse mt-5">
                Loading...
            </p>
            <v-alert border="top" color="red lighten-2" v-if="error.length > 0" dark>
                {{ error.response }}
            </v-alert>
        </div>
        <vue-good-table  v-if="!loading"
          :columns="columns"
          :rows="weblogInfo"  
          styleClass="vgt-table /*striped*/"
          :fixed-header="false"
          compactMode
          :pagination-options="{
            enabled: false,
            perPage: 50,
            mode: 'pages'
          }"  
          :search-options="{
            enabled: false,
            placeholder: 'Search for a customer',
          }"
            @on-row-dblclick="onRowDoubleClick"
            max-height="800px"
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'diffDate'">
              <span v-if="props.row.diffDate == 0 && props.row.diffDate != ''" class="svg-icon"><i class="flaticon2-check-mark text-success"></i></span>
              <span v-if="props.row.diffDate == ''" class="svg-icon"><i class="flaticon-cancel text-warning"></i></span>
              <span v-if="props.row.diffDate != 0" class="text-muted font-weight-bold">{{ props.row.diffDate }}</span>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
        </vue-good-table>    
        </div>
      </div>
    </div>
      <!--end::Content-->
  </template>
  <script>
  import axios from "axios";
  import { mapGetters } from "vuex";
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import 'vue-good-table/dist/vue-good-table.css'
  import { VueGoodTable } from 'vue-good-table';
  export default {
    name: "custom-page",
    components: {
      VueGoodTable,
    },
    data() {
      return {
        loading: true,
        tabIndex: 0,
        debnr: this.$route.params.debnr,
        showPassword: "********",
        clientInfo:[],
        weblogInfo:[],
        error: [],
        data: [],
        columns: [
          {
            label: 'Type',
            field: 'type',
            thClass: 'text-muted font-size-sm',
            tdClass: 'text-muted font-size-sm',
            width: '30%',
            filterOptions: {
              // styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Filter', // placeholder for filter input
              // filterValue: 'Jane', // initial populated value for this filter
              // filterDropdownItems: [], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: 'enter', //only trigger on enter not on keyup 
            },
          },
          {
            label: 'First login',
            field: 'logtime',
            sortable: true,
            thClass: 'text-muted font-size-sm',
            tdClass: 'text-muted font-size-sm',
            width: '30%',
            // type: 'date',
            // dateInputFormat: 'yyyy-MM-dd',
            // dateOutputFormat: 'MMM do yy',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter', // placeholder for filter input
            },
          },
          {
            label: 'Quantity',
            field: 'quantity',
            sortable: false,
            thClass: 'text-muted font-size-sm',
            tdClass: 'text-muted font-size-sm',
            width: '20%',
            // type: 'date',
            // dateInputFormat: 'yyyy-MM-dd',
            // dateOutputFormat: 'MMM do yy',
          },
          {
            label: 'Turnover',
            field: 'turnover',
            sortable: false,
            thClass: 'text-muted font-size-sm',
            tdClass: 'text-muted font-size-sm',
            width: '20%',
          },
        ],
        // lineId: this.$route.params.id,
      }
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Customer > " + this.debnr }]);
      this.getData();
    },
    methods: {
      /**
       * Set current active on click
       * @param event
       */
      getData(){
        axios
        .get("/home/getDebnrDetails?Debnr="+this.debnr)
        .then((response) => {
            this.clientInfo = response.data;
            this.loading = false;
        })
        .catch((error) => {
            this.error.response = "Error msg";
            // console.log(error.response)
            this.loading = false;
            throw error;
        });
        axios
        .get("/home/getWeblogClient?Debnr="+this.debnr)
        .then((response) => {
            this.weblogInfo = response.data;
            this.loading = false;
        })
        .catch((error) => {
            this.error.response = "Error msg";
            // console.log(error.response)
            this.loading = false;
            throw error;
        });
    },
      HidePassword(value) {
        if (value == true) {
          this.showPassword = "********";
        }
        if (value == false) {
          this.showPassword = this.clientInfo.password;
        }
      },
      GoToGoogleMaps() {
        const adresseEncodee = encodeURIComponent(""+this.clientInfo.debAddress+", "+this.clientInfo.city);
        const urlGoogleMaps = `https://www.google.com/maps/place/${adresseEncodee}`;
        window.open(urlGoogleMaps, "_blank");
      },  
    },
    computed: {
      ...mapGetters(["currentUserPersonalInfo"]),
      getFullName() {
        return (
          this.currentUserPersonalInfo.name +
          " " +
          this.currentUserPersonalInfo.surname
        );
      }
    }
  };
  </script>
  